<template>
  <van-form
    :class="{
      'van-form__text-color': !isAdd
    }"
    :show-error-message="false"
    label-width="22vw"
    @submit="onSubmit"
    @failed="onFailed"
  >
    <van-field
      v-if="isAdd"
      :required="isAdd"
      :rules="[{ required: isAdd, message: '提交意见' }]"
      label="提交意见"
      name="passStatus"
    >
      <template #input>
        <van-radio-group
          v-model="approvalInfo.passStatus"
          :disabled="!isAdd || editable"
          direction="horizontal"
        >
          <van-radio
            v-for="item in passStatusData"
            :key="item.value"
            :name="item.value"
            >{{ item.label }}</van-radio
          >
        </van-radio-group>
      </template>
    </van-field>
    <template
      v-if="+approvalInfo.passStatus === 1 && !personInfo.transferApprove"
    >
      <van-field
        :rules="[{ required: isAdd, message: '请填写隐患级别' }]"
        :value="approvalInfo.troubleLevelName"
        :clickable="isAdd"
        label="隐患级别"
        name="troubleLevel"
        :placeholder="placeholder('choose')"
        :readonly="!isAdd"
        :disabled="editable"
        :required="isAdd"
        @click="!editable && handlerTypesClick($event, 'troubleLevel')"
        @focus="preventKeyboard"
      />
      <van-field
        :rules="[{ required: isAdd, message: '请选择所属部门' }]"
        :value="approvalInfo.departmentName"
        :clickable="isAdd"
        label="所属部门"
        name="department"
        :placeholder="placeholder('choose')"
        :readonly="!isAdd"
        :disabled="editable"
        :required="isAdd"
        @click="!editable && handlerTypesClick($event, 'department')"
      />
      <van-field
        v-model="approvalInfo.receiverName"
        :rules="[{ message: '请填写部位名称' }]"
        label="整改责任人"
        name="name"
        :placeholder="placeholder()"
        readonly
        :disabled="editable"
        @click="!editable && onPersonClick($event, 'leader')"
      />
      <van-field
        v-model="approvalInfo.checkerName"
        :rules="[{ message: '请填写部位名称' }]"
        label="整改验收人"
        name="name"
        :placeholder="placeholder()"
        readonly
        :disabled="editable"
        @click="!editable && onPersonClick($event, 'checker')"
      />
      <van-field
        :disabled="!isAdd || editable"
        :required="isAdd"
        :rules="[{ required: true, message: '请选择点击选择时间' }]"
        :value="approvalInfo.planTime"
        :clickable="isAdd"
        label="限期整改"
        name="planTime"
        :placeholder="placeholder('time')"
        readonly
        @click="!editable && handlerTimeClick()"
      />
      <van-field
        v-model="approvalInfo.suggest"
        autosize
        :readonly="!isAdd"
        :disabled="editable"
        label="整改措施"
        maxlength="500"
        name="suggest"
        :placeholder="placeholder()"
        rows="2"
        show-word-limit
        type="textarea"
        @input="handleInput('suggest')"
      />
      <van-field
        v-model="approvalInfo.measure"
        autosize
        :readonly="!isAdd"
        :disabled="editable"
        label="控制措施"
        maxlength="500"
        name="measure"
        :placeholder="placeholder()"
        rows="2"
        show-word-limit
        type="textarea"
        @input="handleInput('measure')"
      />
    </template>
    <template v-else>
      <van-field
        v-model="approvalInfo.content"
        :disabled="!isAdd"
        :show-word-limit="isAdd"
        autosize
        :label="+approvalInfo.passStatus === 2 ? `退回原因` : `备注`"
        maxlength="500"
        :placeholder="placeholder()"
        rows="2"
        type="textarea"
        @input="handleInput('content')"
      />
    </template>
    <template
      v-if="
        +approvalInfo.passStatus === 1 &&
          editable &&
          !personInfo.transferApprove
      "
    >
      <van-field
        v-if="isAdd"
        :required="isAdd"
        :rules="[{ required: true }]"
        label="会签意见"
        :disabled="approveSign"
        name="countersignOpinion"
      >
        <template #input>
          <van-radio-group
            v-model="approvalInfo.countersignOpinion"
            :disabled="!isAdd || approveSign"
            direction="horizontal"
          >
            <van-radio
              v-for="item in opinionOptions"
              :key="item.value"
              :name="item.value"
              >{{ item.label }}</van-radio
            >
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        v-if="!approvalInfo.countersignOpinion"
        v-model="approvalInfo.countersignDesc"
        autosize
        label="不同意原因"
        :readonly="!isAdd"
        maxlength="500"
        name="countersignDesc"
        :placeholder="placeholder()"
        rows="2"
        :required="isAdd"
        :rules="[{ required: true }]"
        show-word-limit
        :disabled="approveSign"
        type="textarea"
        @input="handleInput('countersignDesc')"
      />
    </template>
    <div v-if="isAdd" class="details-page__footer-button-wrapper--fixed">
      <van-button
        v-if="turnAuth && approvalInfo.transferButton"
        :disabled="loading.status || approveSign"
        :loading="loading.status"
        :loading-text="loading.text"
        block
        round
        type="info"
        @click="onTurnApproval"
        >转审</van-button
      >
      <van-button
        :disabled="loading.status || approveSign"
        :loading="loading.status"
        :loading-text="loading.text"
        block
        native-type="submit"
        round
        type="info"
        >提交</van-button
      >
    </div>
    <!-- <div v-if="isAdd" class="footer-button">
      <van-button
        :disabled="loading.status || approveSign"
        :loading="loading.status"
        :loading-text="loading.text"
        block
        native-type="submit"
        round
        type="info"
        >转审</van-button
      >
      <van-button
        :disabled="loading.status || approveSign"
        :loading="loading.status"
        :loading-text="loading.text"
        block
        native-type="submit"
        round
        type="info"
        >提交</van-button
      >
    </div> -->

    <!-- 组件 -->
    <select-popup
      :choose-value="popupValue"
      :data="popupData"
      :title="popupTitle"
      :visible="popupVisible"
      :popup-style="{
        height: '70vh'
      }"
      @close="onPopupClose"
      @confirm="onPopupConfirm"
      @reset="onPopupReset"
    />
    <van-popup v-model="showTimePicker" position="bottom">
      <van-datetime-picker
        v-model="initTime"
        :min-date="minDate"
        type="date"
        @cancel="showTimePicker = false"
        @confirm="onDatetimeConfirm"
      />
    </van-popup>
    <select-person
      :title="selectPersonTitle"
      :visible="selectPersonVisible"
      :choose-value="choosePersonSelect"
      position="bottom"
      @close="onPersonClose"
      @confirm="onPersonConfirm"
    />
  </van-form>
</template>

<script>
import { passStatusData, opinionOptions } from "./utils/constant";
import { filterEmoji, parseTime, operateMessage } from "@/utils";
import { loading } from "@/utils/constant";
import getSelectMixin from "@/views/trouble/mixin/getSelectMixin";
import {
  approveTrouble,
  refuseApproves,
  getApprovePersonConf,
  getTroubleApproveInfoById,
  notTrouble,
  getTroubleInfoById,
  troubleApprovesTempRecord,
  countersignTrouble,
  troubleApprovesNode,
  troubleTransfer,
  nodeCountersign
} from "@/api/psm/trouble";
import { Toast } from "vant";
import SelectPerson from "@/components/SelectPerson";
import SelectPopup from "@/components/SelectPopup";
import { mapState } from "vuex";

export default {
  components: { SelectPopup, SelectPerson },
  mixins: [getSelectMixin],
  props: ["id"],
  inject: {
    detailsComponents: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      opinionOptions,
      editable: false,
      passStatusData,
      isAdd: false,
      minDate: new Date(),
      initTime: new Date(),
      loading: { ...loading },
      approvalInfo: {
        troubleId: "",
        passStatus: "1",
        content: "",
        troubleLevelName: "",
        troubleLevel: "",
        planTime: "",
        measure: "",
        suggest: "",
        // 整改责任人
        leader: [],
        receiverName: "",
        // 整改验收人
        checker: [],
        checkerName: "",
        department: "",
        departmentName: "",
        countersignOpinion: 1,
        countersignDesc: ""
      },
      popupTypesConf: {
        troubleLevel: {
          title: "隐患级别",
          keyName: "troubleLevelName",
          typesKey: "levelTypes"
        },
        department: {
          title: "所属部门",
          keyName: "departmentName",
          typesKey: "departmentSelect"
        }
      },
      personConf: {
        checker: {
          select: [],
          title: "整改验收人",
          typeNameKey: "checkerName",
          selectTypes: "checkerSelect"
        },
        leader: {
          select: [],
          title: "整改责任人",
          typeNameKey: "receiverName",
          selectTypes: "leaderSelect"
        }
      },
      approveSign: false,
      popupType: "",
      popupTypeName: "",
      popupValue: "",
      popupVisible: false,
      popupTitle: "",
      popupData: [],
      showTimePicker: false,
      selectPersonVisible: false,
      selectPersonTitle: "",
      choosePersonSelect: [],
      leaderSelect: [],
      checkerSelect: [],
      departmentSelect: [],
      currentPersonType: "",
      personInfo: {
        troubleId: "",
        name: "",
        countersign: "",
        approvers: "",
        transferApprove: 0,
        id: "",
        firstOperate: 0
      }
    };
  },
  computed: {
    ...mapState({
      btnAuthMap: state => state.menu.btnAuthMap,
      userInfo: state => state.login.userInfo
    }),
    turnAuth() {
      // 跟pc端隐患配置一样的权限，troubleconf属性存放隐患配置
      // const temp = this.btnAuthMap?.troubleconf?.turnApproves || false;
      const temp = globalConfig?.VUE_APP_TURN_APPROVES === "true";
      return temp;
    },
    troubleInfo() {
      return this.detailsComponents.troubleInfo;
    }
  },
  watch: {
    "approvalInfo.passStatus"(val) {
      if (+val === 0) {
        Object.keys(this.approvalInfo).forEach(key => {
          if (
            key !== "troubleId" &&
            key !== "passStatus" &&
            key !== "content" &&
            key !== "leader" &&
            key !== "receiverName" &&
            key !== "checker" &&
            key !== "checkerName" &&
            key !== "troubleLevelName" &&
            key !== "troubleLevel"
          ) {
            this.approvalInfo[key] = "";
          }
        });
      } else if (+val === 1) {
        this.approvalInfo.content = "";
      }
    }
  },
  async created() {
    this.isAdd = JSON.parse(this.$route.query.add);
    if (!this.isAdd) {
      this.getInfo();
    } else {
      await this.setInfo();
    }
    this.approvalInfo.planTime = this.approvalInfo.planTime
      ? this.approvalInfo.planTime
      : this.troubleInfo.planTime;
    this.approveSign = false;
    this.editable = false;
    this.$nextTick(() => {
      if (
        +this.troubleInfo.transferButton ||
        +this.troubleInfo.transferOperate ||
        +this.troubleInfo.disposeStatus === 1
      ) {
        this.getPersonNode();
      }
      this.gettroubleRecord();
    });
  },
  methods: {
    async gettroubleRecord() {
      try {
        const res = await troubleApprovesTempRecord({
          troubleId: this.id
        });
        if (res) {
          this.editable = true;
          this.approveSign = Boolean(+res.approveSign);
          let countersignOpinion = 1;
          let countersignDesc = "";
          if (res?.operateRecordsVO) {
            countersignOpinion = +res?.operateRecordsVO?.countersignOpinion;
            countersignDesc = res?.operateRecordsVO?.countersignDesc;
          }
          this.approvalInfo = {
            countersignOpinion,
            countersignDesc,
            troubleId: res.troubleId,
            passStatus: this.approvalInfo.passStatus,
            troubleLevel: res.approveTroubleLevel,
            department: res.approveDepartment,
            suggest: res.approveSuggest,
            measure: res.approveMeasure,
            planTime: res.approvePlanTime,
            // 整改责任人
            leader: res.approveRectifyPersonSelect,
            // 整改验收人
            checker: res.approveCheckerPersonSelect,

            content: this.approvalInfo.content,
            troubleLevelName: this.approvalInfo.troubleLevelName,
            checkerName: this.approvalInfo.checkerName,
            departmentName: this.approvalInfo.departmentName,
            receiverName: this.approvalInfo.receiverName,
            transferOperate: this.approvalInfo.transferOperate,
            transferButton: this.approvalInfo.transferButton
          };
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getPersonNode() {
      try {
        const params = { troubleId: this.troubleInfo.id };
        const res = await troubleApprovesNode(params);
        if (res) {
          this.personInfo = { ...(res || this.personInfo) };
        }
      } catch (error) {
        console.log(error);
      }
    },
    async onTurnApproval() {
      try {
        const params = {
          troubleId: this.troubleInfo.id,
          // approveNodeOperateId: this.personInfo.id,
          passStatus: 1
        };
        this.turnApproval({ params });
        // const res = await troubleTransfer(params);
        // if (res) {
        //   this.$message.success(res.msg || "转审成功");
        // }
      } catch (error) {
        console.log(error);
      }
    },
    async turnApproval(data) {
      try {
        const res = await troubleTransfer(data.params);
        if (res) {
          this.$router.back();
          this.$message.success(res.msg || data.msg || "转审成功");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async signToggle(info) {
      try {
        const params = {
          troubleId: info.troubleId,
          stage: +this.troubleInfo.stage,
          operate: info.operate,
          countersignOpinion: info.countersignOpinion,
          countersignDesc: info.countersignDesc
        };
        const res = await countersignTrouble(params);
        if (res) this.$router.back();
      } catch (error) {
        console.log(error);
      }
    },
    async setInfo() {
      let troubleInfo;
      if (!this.troubleInfo?.id) {
        troubleInfo = await getTroubleInfoById(this.id);
      } else {
        troubleInfo = this.troubleInfo;
      }
      const {
        troubleLevel,
        troubleLevelName,
        measure,
        suggest,
        gist,
        department,
        departmentName,
        transferOperate,
        transferButton
      } = troubleInfo;
      this.approvalInfo.troubleLevel = troubleLevel;
      this.approvalInfo.troubleLevelName = troubleLevelName;
      this.approvalInfo.measure = measure;
      this.approvalInfo.suggest = suggest;
      this.approvalInfo.gist = gist;
      this.approvalInfo.department = department;
      this.approvalInfo.departmentName = departmentName;
      this.approvalInfo.transferOperate = transferOperate;
      this.approvalInfo.transferButton = transferButton;
      this.getApprovePerson(this.id, department, troubleLevel);
    },
    async getInfo() {
      try {
        this.approvalInfo = await getTroubleApproveInfoById(this.id);
        this.setInfo();
      } catch (e) {
        console.log(e);
      }
    },
    async getApprovePerson(id, department = "", troubleLevel = "") {
      try {
        const config = await getApprovePersonConf(
          id,
          department,
          troubleLevel,
          this.userInfo.orgCode
        );
        if (!config) return;
        const {
          checkers,
          receivers,
          receiverSelect,
          checkerSelect,
          departmentsSelect
        } = config;
        this.approvalInfo.checker = checkers;
        this.approvalInfo.checkerName = checkerSelect.map(x => x.label).join();
        this.approvalInfo.leader = receivers;
        this.approvalInfo.receiverName = receiverSelect
          .map(x => x.label)
          .join();
        this.personConf.leader.select = this.leaderSelect = receiverSelect;
        this.personConf.checker.select = this.checkerSelect = checkerSelect;
        this.departmentSelect = departmentsSelect;
      } catch (e) {
        console.log(e);
      }
    },
    onPersonClick(ev, type) {
      if (!this.isAdd) return;
      ev.preventDefault();
      const config = this.personConf;
      this.selectPersonTitle = config[type].title;
      this.choosePersonSelect = [...config[type].select];
      this.selectPersonVisible = true;
      this.currentPersonType = type;
    },
    onPersonConfirm(ids, list) {
      const currentPersonType = this.currentPersonType;
      const config = this.personConf[currentPersonType];
      this.approvalInfo[currentPersonType] = ids;
      this.approvalInfo[config.typeNameKey] = list.map(x => x.label).join();
      this.personConf[currentPersonType].select = this[config.selectTypes] = [
        ...list
      ];
    },
    onPersonClose() {
      this.selectPersonVisible = false;
    },
    placeholder(type = "content") {
      let content = "请输入内容";
      if (type === "time") {
        content = "点击选择时间";
      }
      if (type === "choose") {
        content = "请选择";
      }
      return this.isAdd ? content : "--";
    },
    handleInput(key) {
      this.approvalInfo[key] = filterEmoji(this.approvalInfo[key]);
    },
    preventKeyboard() {
      document.activeElement.blur();
    },
    handlerTypesClick(ev, key) {
      ev.preventDefault();
      if (!this.isAdd) return;
      // 如果之前被选中了，那就直接传递过去
      this.popupValue = this.approvalInfo[key];

      const popupTypesConf = this.popupTypesConf;
      this.popupVisible = true;
      this.popupTitle = popupTypesConf[key].title;

      this.popupData = [...this[popupTypesConf[key].typesKey]];
      this.popupType = key;
      this.popupTypeName = popupTypesConf[key].keyName;
    },
    onPopupConfirm(row) {
      this.approvalInfo[this.popupType] = row.value;
      this.approvalInfo[this.popupTypeName] = row.label;
      if (this.popupType === "troubleLevel") {
        // 更新部门
        this.approvalInfo.department = "";
        this.approvalInfo.departmentName = "";
        this.getApprovePerson(this.id, "", row.value);
      } else if (this.popupType === "department") {
        // 更新人员
        this.approvalInfo.checker = [];
        this.approvalInfo.leader = [];
        this.approvalInfo.checkerName = "";
        this.approvalInfo.receiverName = "";
        this.personConf.leader.select = [];
        this.personConf.checker.select = [];
        this.getApprovePerson(
          this.id,
          row.value,
          this.approvalInfo.troubleLevel
        );
      }
    },
    onPopupClose() {
      this.popupVisible = false;
      this.popupData = [];
    },
    onPopupReset() {
      this.approvalInfo[this.popupType] = "";
      this.popupValue = "";
    },
    handlerTimeClick() {
      if (!this.isAdd) return;
      this.approvalInfo.planTime &&
        (this.initTime = new Date(this.approvalInfo.planTime));
      this.showTimePicker = true;
    },
    onDatetimeConfirm(time) {
      this.approvalInfo.planTime = parseTime(time, "{y}-{m}-{d}");
      this.showTimePicker = false;
    },
    async onRefuse() {
      try {
        const ret = await refuseApproves(this.approvalInfo);
        operateMessage(!!ret, "审批拒绝");
        if (ret) this.$router.back();
      } catch (e) {
        console.log("onRefuse -> e", e);
      }
    },
    async onPass() {
      try {
        const { leader, checker } = this.approvalInfo;
        if (!leader.length || !checker.length) {
          return Toast("请选择整改责任人或整改验收人");
        }
        const ret = await approveTrouble(this.approvalInfo);
        operateMessage(!!ret, "审批通过");
        if (ret) this.$router.back();
      } catch (e) {
        console.log("onPass -> e", e);
      }
    },
    onFailed(errorInfo) {
      console.log(errorInfo, "errorInfo");
    },
    async notTrouble() {
      try {
        const r = await notTrouble(this.approvalInfo);
        operateMessage(!!r, "不是隐患");
        if (r) {
          this.$router.back();
        }
      } catch (e) {
        console.log(e);
      }
    },
    async setTurnPerson() {
      try {
        const params = {
          troubleId: this.troubleInfo.id,
          approveNodeOperateId: this.personInfo.id,
          passStatus: +this.approvalInfo.passStatus
        };
        const res = await nodeCountersign(params);
        if (res) {
          this.$router.back();
          this.$message.success(res || "审核成功");
        }
      } catch (error) {
        console.log(error);
      }
    },
    onSubmit() {
      console.log(this.id);
      this.approvalInfo.troubleId = this.id;
      const passStatus = +this.approvalInfo.passStatus;
      if (
        +this.personInfo.transferApprove &&
        ((passStatus !== 1 && !+this.personInfo.firstOperate) ||
          passStatus === 1)
      ) {
        this.setTurnPerson();
        // if (this.approvalInfo) {
        //   const params = {
        //     troubleId: this.approvalInfo.troubleId,
        //     approveNodeOperateId: this.personInfo.id,
        //     passStatus: +this.approvalInfo.passStatus
        //   };
        //   this.turnApproval({ params, msg: "审核成功" });
        return;
      }
      if (passStatus === 2) {
        this.onRefuse();
      } else if (passStatus === 1) {
        if (this.editable) {
          const params = {
            ...this.approvalInfo,
            operate: 2
          };
          this.signToggle(params);
        } else {
          this.onPass();
        }
      } else {
        this.notTrouble();
      }
    }
  }
};
</script>

<style lang="scss" scope>
.van-form {
  height: calc(100% - 80px);
  overflow-y: auto;
}
.details-page__footer-button-wrapper--fixed {
  .van-button + .van-button {
    margin-left: 20px;
  }
}
</style>
